<template>
  <div class="flex min-h-screen max-h-screen overflow-y-hidden">
    <div v-if="loadFontManager">
      <!-- Add the font manager component. It will preload all the fonts for the whole app -->
      <font-manager />
    </div>

    <div
      class="
        w-80
        bg-truegray-700
        min-h-screen
        flex-shrink-0
        px-6
        pt-6
        overflow-y-auto
      "
    >
      <div class="px-8 mb-6">
        <img src="../assets/images/logo_white.png" />
      </div>
      <div v-if="showingGallery" class="text-white">
        <p class="text-lg mb-2 leading-tight font-bold">
          Willkommen beim Schickbox Vorlagen-Editor!
        </p>
        <p>
          Wähle rechts eine Vorlage aus oder lade eine deiner eigenen Vorlagen.
        </p>

        <div class="mt-2">
          <label
            class="
              inline-flex
              items-center
              transition-all
              duration-150
              text-gray-700
              bg-white
              hover:bg-gray-50
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-gray-700
              px-3
              py-2
              text-sm
              leading-4
              font-medium
              rounded-md
              shadow-sm
              w-full
              justify-center
              cursor-pointer
            "
          >
            <fa icon="upload" class="mr-2" />
            <span>Vorlage importieren (.json)</span>
            <input type="file" accept=".json" ref="jsonField" class="hidden" />
          </label>
        </div>

        <div
          v-if="validOrder"
          class="mt-5 text-white text-xs bg-teal-500 rounded-lg p-4"
        >
          <span><strong>Bestellung:</strong> #{{ orderId }}</span
          ><br />
          <span><strong>E-Mail:</strong> {{ orderEmail }}</span>
        </div>
      </div>

      <div v-else class="divide-y divide-truegray-600 space-y-6">
        <div class="flex flex-col space-y-2">
          <div>
            <label
              class="
                inline-flex
                items-center
                transition-all
                duration-150
                border border-truegray-500
                text-white
                bg-truegray-600
                hover:bg-truegray-700
                px-3
                py-2
                text-sm
                leading-4
                font-medium
                rounded-md
                shadow-sm
                w-full
                justify-center
                cursor-pointer
              "
            >
              <fa icon="image" class="mr-2" />
              <span>Bild hinzufügen</span>
              <input
                type="file"
                accept=".jpg,.jpeg,.png"
                ref="imgField"
                class="hidden"
              />
            </label>
          </div>
          <div>
            <base-button
              type="dark"
              size="sm"
              icon="text"
              full-width
              @click="addTextLayer"
              >Text hinzufügen</base-button
            >
          </div>

          <div>
            <base-button
              v-if="validOrder"
              type="primary"
              size="sm"
              icon="upload"
              :loading="gettingOrderStatus"
              full-width
              @click="showUploadTemplateDialog"
              >Vorlage hochladen
            </base-button>
            <base-button
              v-else
              type="dark"
              size="sm"
              icon="save"
              full-width
              @click="downloadTemplateZip"
              >Vorlage exportieren
            </base-button>
          </div>

          <div class="flex space-x-1">
            <base-button
                icon="undo"
                type="dark"
                size="xs"
                full-width
                @click="canvasUndo"
            >Schritt zurück</base-button
            >
            <base-button
                icon="redo"
                type="dark"
                size="xs"
                full-width
                @click="canvasRedo"
            >Wiederherstellen</base-button
            >
          </div>

          <base-button @click="refreshPage"
                       size="sm"
                       icon="long-arrow-left"
                       full-width
                       type="dark"> Zurück </base-button>

          <!-- <div class="space-y-2">
            <base-button
              type="dark"
              size="sm"
              full-width
              @click="addGuidesLandscape"
              >Hilfslinien (Normal)
            </base-button>
            <base-button
              type="dark"
              size="sm"
              full-width
              @click="addGuidesStripe"
              >Hilfslinien (Streifen)
            </base-button>
          </div> -->

          <div
            v-if="validOrder"
            class="
              text-white text-xs
              border border-truegray-500
              rounded-lg
              py-2
              px-3
            "
          >
            <span><strong>Bestellung:</strong> #{{ orderId }}</span
            ><br />
            <span><strong>E-Mail:</strong> {{ orderEmail }}</span>
          </div>

          <base-dialog v-model:show="showingUploadDialog">
            <DialogTitle
              as="h3"
              class="text-lg font-medium leading-6 text-truegray-900"
            >
              Vorlage zu Schickbox hochladen
            </DialogTitle>

            <div class="mt-2 text-sm text-truegray-600">
              <p>
                Nach dem Klick auf »Hochladen« wird deine Vorlage zu Schickbox
                hochgeladen und für folgende Bestellung gespeichert:
              </p>
              <p class="mt-1.5">
                <span><strong>Bestellung:</strong> #{{ orderId }}</span
                ><br />
                <span><strong>E-Mail:</strong> {{ orderEmail }}</span>
              </p>
              <div
                v-if="overwritingExistingTemplate"
                class="
                  mt-2
                  bg-orange-300
                  rounded-lg
                  py-2.5
                  px-4
                  text-orange-900 text-xs
                "
              >
                <strong>Achtung!</strong><br />
                Du überschreibst damit deine vorher hochgeladene Vorlage.
              </div>
            </div>
            <template v-slot:footer="slotProps">
              <base-button
                :loading="uploadingTemplate"
                :disable-on-loading="true"
                type="primary"
                @click="uploadTemplate"
              >
                Hochladen
              </base-button>
              <base-button @click="slotProps.close"> Abbrechen </base-button>
            </template>
          </base-dialog>

          <base-dialog v-model:show="showingUploadSuccessDialog">
            <DialogTitle
              as="h3"
              class="text-lg font-medium leading-6 text-green-600"
            >
              Upload erfolgreich!
            </DialogTitle>

            <div class="mt-2 text-sm text-truegray-600">
              <p>
                Deine Vorlage wurde erfolgreich zu Schickbox hochgeladen.
                <a
                  class="text-blue-500 underline"
                  href="https://verwaltung.schickbox.de/de/customer/login"
                  target="_blank"
                  >Zum Kundenbereich</a
                >
              </p>
            </div>
            <template v-slot:footer="slotProps">
              <base-button @click="slotProps.close"> Schließen </base-button>
            </template>
          </base-dialog>
        </div>

        <!-- <div class="pt-3" v-if="layers.length > 0">
          <span class="text-xs uppercase font-medium text-white">Ebenen</span>
          <div
            class="w-full bg-white max-h-60 px-3 py-2 rounded-md text-xs mt-0.5"
          >
            <ul class="space-y-1">
              <li
                @click="selectObjectByName(layer.name)"
                class="block w-full py-0.5 px-2 rounded-sm cursor-pointer"
                :class="{
                  'bg-gray-700 text-white': selectedObjectName === layer.name,
                }"
                v-for="layer in layers"
                :key="layer.name"
              >
                {{ layer.type }} ({{ layer.name }})
              </li>
            </ul>
          </div>
        </div> -->

        <div class="pt-3">
          <h2 class="text-white text-sm mb-2">Hintergrundfarbe</h2>
          <vue-gpickr v-model="canvasGradient" />
        </div>

        <div v-if="selectedObject" class="pt-3 space-y-2">
          <item-dimensions-control />

          <div class="flex space-x-1 pt-4">
            <item-alignment-control />
          </div>

          <div
            class="flex space-x-1"
            v-if="selectedObjectAttributes.type === 'image'"
          >
            <base-button
              icon="intersection"
              type="dark"
              size="xs"
              full-width
              @click="setMaskingOn"
              >Als Hintergrund verwenden</base-button
            >
            <base-button
              icon="union"
              type="dark"
              size="xs"
              full-width
              @click="setMaskingOff"
              >Grafik in den Vordergrund</base-button
            >
          </div>

          <div class="flex space-x-1">
            <base-button
              type="dark"
              icon="bring-front"
              size="xs"
              full-width
              @click="bringForward"
              >Nach vorne bringen</base-button
            >
          </div>

          <div class="flex space-x-1">
            <base-button
              icon="lock"
              type="dark"
              size="xs"
              full-width
              @click="lockObject"
              >Schützen</base-button
            >
            <base-button
              icon="unlock-alt"
              type="dark"
              size="xs"
              full-width
              @click="unlockObject"
              >Kein Schutz</base-button
            >
          </div>

          <div class="flex space-x-1">
            <base-button
              type="dark"
              size="xs"
              full-width
              icon="clone"
              @click="duplicateObject"
              >Duplizieren</base-button
            >
            <base-button
              icon="trash-alt"
              type="dark"
              size="xs"
              full-width
              @click="removeObject"
              >Löschen</base-button
            >
          </div>

          <div v-if="selectedObjectAttributes.type === 'i-text'">
            <div class="w-full mt-6">
              <label
                class="block text-xs uppercase font-medium text-white mb-0.5"
                >Schriftart</label
              >
              <font-picker-tool v-model="selectedObjectFont" />
            </div>

            <div class="w-full mt-6">
              <ColorPicker
                theme="dark"
                :color="selectedObjectAttributes.color"
                @changeColor="setSelectedObjectColor"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full min-h-screen overflow-x-hidden">
      <div v-if="showingGallery" class="w-full p-6">
        <h2 class="font-bold text-2xl mb-2 text-truegray-700">
          Leere Vorlagen
        </h2>
        <div class="grid grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6">
          <div
            v-for="template in emptyTemplates"
            :key="template.name"
            class="
              bg-gray-200
              shadow-md
              rounded-md
              p-4
              xl:p-5
              2xl:p-6
              w-full
              space-y-2
              flex flex-col
            "
          >
            <h2 class="font-bold">{{ template.name }}</h2>
            <img :src="template.preview" />
            <div class="flex-grow"></div>
            <base-button
              class="mt-1"
              type="primary"
              full-width
              @click="loadTemplate(template.url)"
              >Auswählen</base-button
            >
          </div>
        </div>
        <h2 class="mt-6 font-bold text-2xl mb-2 text-truegray-700">
          Vorgefertigt
        </h2>
        <div class="grid grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6">
          <div
            v-for="template in prebuiltTemplates"
            :key="template.name"
            class="
              bg-gray-200
              shadow-md
              rounded-md
              p-4
              xl:p-5
              2xl:p-6
              w-full
              space-y-2
              flex flex-col
            "
          >
            <h2 class="font-bold">{{ template.name }}</h2>
            <img :src="template.preview" />
            <div class="flex-grow"></div>
            <base-button
              class="mt-1"
              type="primary"
              full-width
              @click="loadTemplate(template.url)"
              >Auswählen</base-button
            >
          </div>
        </div>
      </div>
      <div
        v-else
        id="fabric-canvas-wrapper"
        ref="canWrapper"
        class="w-full min-h-screen"
      >
        <canvas ref="canvas" width="800" height="600"></canvas>
      </div>
    </div>

    <base-dialog v-if="!showingGallery" v-model:show="showingGuideWarning">
      <DialogTitle
          as="h3"
          class="text-lg font-medium leading-6 text-orange-600"
      >
        Achte auf die Hilfslinien!
      </DialogTitle>

      <div class="mt-2 text-sm text-truegray-600">
        <p>
          Achte darauf keine Text- oder Bildelemente außerhalb der türkisen
          Hilfslinien zu platzieren, da diese sonst möglicherweise beim Druck
          abgeschnitten werden.
        </p>
      </div>
      <template v-slot:footer="slotProps">
        <base-button @click="slotProps.close"> Verstanden </base-button>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import { ref, onMounted, nextTick, computed } from "vue";
import {useRoute, useRouter} from "vue-router";
import decode from "jwt-decode";
import { DialogTitle } from "@headlessui/vue";
import { ColorPicker } from "vue-color-kit";
// import "vue-color-kit/dist/vue-color-kit.css";
import useCanvas from "@/composables/canvas";
import FontManager from "@/components/FontManager";
import FontPickerTool from "@/components/tools/FontPicker";
import BaseButton from "@/components/BaseButton";
import BaseDialog from "@/components/BaseDialog";
import ItemDimensionsControl from "@/components/ItemDimensionsControl";
import ItemAlignmentControl from "@/components/ItemAlignmentControl";
import "fabric-history";
import { VueGpickr, LinearGradient } from 'vue-gpickr';

export default {
  components: {
    BaseButton,
    FontManager,
    FontPickerTool,
    BaseDialog,
    DialogTitle,
    ColorPicker,
    ItemDimensionsControl,
    ItemAlignmentControl,
    VueGpickr
  },
  watch: {
    // whenever color changes, this function will run
    canvasGradient(newColors, oldColors) {
      if(this.watchingCanvasGradient){
        this.setCanvasGradientFromPicker(newColors);
      }
    }
  },
  methods: {
    refreshPage() {
      if(confirm("Bist du dir sicher? Nicht gespeicherte Änderungen gehen möglicherweise verloren.")){
        // Reload the current page
        this.disposeCanvas();
        this.showingGallery = true;
        this.canvas = null;
      }
    }
  },
  setup() {
    const route = useRoute();
    const jwt = ref(null);
    const orderId = ref(null);
    const orderEmail = ref(null);
    const canvas = ref(null);
    const canWrapper = ref(null);
    const jsonField = ref(null);
    const imgField = ref(null);
    const showingGallery = ref(true);
    const loadFontManager = ref(false);
    const watchingCanvasGradient = ref(false);
    const canvasGradient = ref(null);
    const showingUploadDialog = ref(false);
    const showingGuideWarning = ref(true);
    const showingUploadSuccessDialog = ref(false);
    const gettingOrderStatus = ref(false);
    const uploadingTemplate = ref(false);
    const overwritingExistingTemplate = ref(false);
    const {
      initCanvas,
      disposeCanvas,
      loadJSONTemplate,
      addTextLayer,
      resizeCanvas,
      handleImageSelect,
      getTemplateAsZip,
      selectedObject,
      selectedObjectAttributes,
      selectedObjectName,
      setMaskingOn,
      setMaskingOff,
      lockObject,
      unlockObject,
      duplicateObject,
      removeObject,
      bringForward,
      selectedObjectFont,
      setSelectedObjectColor,
      downloadTemplateZip,
      layers,
      selectObjectByName,
      canvasUndo,
      canvasRedo,
      getCanvasGradientForPicker,
      setCanvasGradientFromPicker,
      // addGuidesLandscape,
      // addGuidesStripe,
    } = useCanvas();

    const emptyTemplates = [
      {
        name: "Einzelfoto",
        url: "empty/einzelfoto",
        preview: require("../assets/images/templates/vorschaubild-einzelfoto-leer.jpg"),
      },
      {
        name: "Collage",
        url: "empty/collage",
        preview: require("../assets/images/templates/vorschaubild-collage-leer.jpg"),
      },
      {
        name: "Streifen",
        url: "empty/streifen",
        preview: require("../assets/images/templates/vorschaubild-streifen-leer.jpg"),
      },
    ];

    const prebuiltTemplates = [
      {
        name: "L1",
        url: "prebuilt/L1",
        preview: require("../assets/images/templates/L1-Vorschau.jpg"),
      },
      {
        name: "L2",
        url: "prebuilt/L2",
        preview: require("../assets/images/templates/L2-Vorschau.jpg"),
      },
      {
        name: "L3",
        url: "prebuilt/L3",
        preview: require("../assets/images/templates/L3-Vorschau.jpg"),
      },
      {
        name: "L4",
        url: "prebuilt/L4",
        preview: require("../assets/images/templates/L4-Vorschau.jpg"),
      },
      {
        name: "L5",
        url: "prebuilt/L5",
        preview: require("../assets/images/templates/L5-Vorschau.jpg"),
      },
      {
        name: "L6",
        url: "prebuilt/L6",
        preview: require("../assets/images/templates/L6-Vorschau.jpg"),
      },
      {
        name: "L7",
        url: "prebuilt/L7",
        preview: require("../assets/images/templates/L7-Vorschau.jpg"),
      },
      {
        name: "L8",
        url: "prebuilt/L8",
        preview: require("../assets/images/templates/L8-Vorschau.jpg"),
      },
      {
        name: "C1",
        url: "prebuilt/C1",
        preview: require("../assets/images/templates/C1-Vorschau.jpg"),
      },
      {
        name: "C2",
        url: "prebuilt/C2",
        preview: require("../assets/images/templates/C2-Vorschau.jpg"),
      },
      {
        name: "C3",
        url: "prebuilt/C3",
        preview: require("../assets/images/templates/C3-Vorschau.jpg"),
      },
      {
        name: "C4",
        url: "prebuilt/C4",
        preview: require("../assets/images/templates/C4-Vorschau.jpg"),
      },
      {
        name: "C5",
        url: "prebuilt/C5",
        preview: require("../assets/images/templates/C5-Vorschau.jpg"),
      },
      {
        name: "C6",
        url: "prebuilt/C6",
        preview: require("../assets/images/templates/C6-Vorschau.jpg"),
      },
      {
        name: "C7",
        url: "prebuilt/C7",
        preview: require("../assets/images/templates/C7-Vorschau.jpg"),
      },
      {
        name: "C8",
        url: "prebuilt/C8",
        preview: require("../assets/images/templates/C8-Vorschau.jpg"),
      },
      {
        name: "S1",
        url: "prebuilt/S1",
        preview: require("../assets/images/templates/S1-Vorschau.jpg"),
      },
      {
        name: "S2",
        url: "prebuilt/S2",
        preview: require("../assets/images/templates/S2-Vorschau.jpg"),
      },
      {
        name: "S3",
        url: "prebuilt/S3",
        preview: require("../assets/images/templates/S3-Vorschau.jpg"),
      },
      {
        name: "S4",
        url: "prebuilt/S4",
        preview: require("../assets/images/templates/S4-Vorschau.jpg"),
      },
      {
        name: "S5",
        url: "prebuilt/S5",
        preview: require("../assets/images/templates/S5-Vorschau.jpg"),
      },
      {
        name: "S6",
        url: "prebuilt/S6",
        preview: require("../assets/images/templates/S6-Vorschau.jpg"),
      },
      {
        name: "S7",
        url: "prebuilt/S7",
        preview: require("../assets/images/templates/S7-Vorschau.jpg"),
      },
      {
        name: "S8",
        url: "prebuilt/S8",
        preview: require("../assets/images/templates/S8-Vorschau.jpg"),
      },
    ];

    onMounted(() => {

      canvasGradient.value = new LinearGradient({
        angle: 0,
        stops: [
          ['#ffffff', 0],
          ['#ffffff', 1],
        ]
      });

      // Read JWT from query param
      if (route.query.sid) {
        const token = decode(route.query.sid);

        if (!token.exp) {
          alert("Zugriffscode ungültig. Bitte neuen holen.");
        }

        const date = new Date(0);
        date.setUTCSeconds(token.exp);

        if (date < new Date()) {
          alert("Zugriffscode abgelaufen. Bitte neuen holen.");
        } else {
          console.log("Zugriffscode gültig!");
          jwt.value = route.query.sid;
          orderId.value = token.order;
          orderEmail.value = token.sub;
        }
      }

      window.onbeforeunload = function () {
        return "Bist du dir sicher? Nicht gespeicherte Änderungen gehen möglicherweise verloren.";
      };

      // Import Json
      jsonField.value.addEventListener("change", function (e) {
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.onload = async function (f) {
          var data = f.target.result;

          showingGallery.value = false;
          await nextTick();

          initCanvas(canvas, canWrapper);
          await nextTick();

          imgField.value.addEventListener("change", handleImageSelect);

          loadJSONTemplate(data, function () {
            nextTick(function(){
              let templateColors = getCanvasGradientForPicker();
              canvasGradient.value = new LinearGradient(templateColors);
              watchingCanvasGradient.value = true;
            });
          }, function (error) {
            // Handle error if loadFromJSON fails
          });
        };
        reader.readAsText(file);
      });

      window.addEventListener("resize", resizeCanvas);
    });

    window.addEventListener("history-changed", function(){
      watchingCanvasGradient.value = false;

      let templateColors = getCanvasGradientForPicker();
      canvasGradient.value = new LinearGradient(templateColors);

      watchingCanvasGradient.value = true;
    });

    const validOrder = computed(
      () => jwt.value && orderId.value && orderEmail.value
    );

    function nextTick(callback) {
      setTimeout(callback);
    }

    async function loadTemplate(name) {
      showingGallery.value = false;
      await nextTick();

      initCanvas(canvas, canWrapper);

      let file = await import("../assets/templates/" + name + ".json");
      await nextTick();

      imgField.value.addEventListener("change", handleImageSelect);

      loadJSONTemplate(file, function () {
        nextTick(function(){
          let templateColors = getCanvasGradientForPicker();
          canvasGradient.value = new LinearGradient(templateColors);
          watchingCanvasGradient.value = true;
        });
      }, function (error) {
        // Handle error if loadFromJSON fails
      });
    }

    async function showUploadTemplateDialog() {
      gettingOrderStatus.value = true;

      const res = await fetch(
        `${process.env.VUE_APP_API_BASE_URL}/v1/orders/get_status?jwt=${jwt.value}`
      );
      if (!res.ok) {
        alert(
          "Zugriffscode abgelaufen oder ungültig. Bitte Editor über Kundenbereich aufrufen."
        );
        gettingOrderStatus.value = false;
        return;
      }

      const data = await res.json();
      gettingOrderStatus.value = false;

      if (data.upload_possible == false) {
        alert("Upload für diese Bestellung ist nicht mehr möglich");
        return;
      }

      if (data.template_exists == true) {
        overwritingExistingTemplate.value = true;
      } else {
        overwritingExistingTemplate.value = false;
      }

      showingUploadDialog.value = true;
    }

    async function uploadTemplate() {
      uploadingTemplate.value = true;
      const formData = new FormData();
      const zip = await getTemplateAsZip();
      const blob = new Blob([zip.buffer]);
      formData.append("jwt", jwt.value);
      formData.append("file", blob);
      formData.append("_method", "PATCH");

      const res = await fetch(
        `${process.env.VUE_APP_API_BASE_URL}/v1/orders/upload_template`,
        {
          method: "POST",
          body: formData,
        }
      );
      if (!res.ok) {
        uploadingTemplate.value = false;
        alert("Fehler beim Hochladen");
        return;
      }

      uploadingTemplate.value = false;
      showingUploadDialog.value = false;
      showingUploadSuccessDialog.value = true;
    }

    window.addEventListener('DOMContentLoaded', function(){
      loadFontManager.value = true;
    });


    return {
      disposeCanvas,
      canWrapper,
      canvas,
      showingGallery,
      loadFontManager,
      watchingCanvasGradient,
      canvasGradient,
      loadTemplate,
      validOrder,
      orderId,
      orderEmail,
      jsonField,
      imgField,
      showingUploadDialog,
      showingUploadSuccessDialog,
      showUploadTemplateDialog,
      gettingOrderStatus,
      addTextLayer,
      uploadTemplate,
      uploadingTemplate,
      overwritingExistingTemplate,
      selectedObject,
      selectedObjectAttributes,
      bringForward,
      setMaskingOn,
      setMaskingOff,
      lockObject,
      unlockObject,
      duplicateObject,
      removeObject,
      selectedObjectFont,
      setSelectedObjectColor,
      downloadTemplateZip,
      // addGuidesLandscape,
      // addGuidesStripe,
      emptyTemplates,
      prebuiltTemplates,
      layers,
      selectedObjectName,
      selectObjectByName,
      canvasUndo,
      canvasRedo,
      getCanvasGradientForPicker,
      setCanvasGradientFromPicker,
      showingGuideWarning
    };
  },
};
</script>